export default [
  {
    header: 'Acesso rápido',
  },  
  {
    title: 'Dashboard',
    route: 'dashboard-manager',
    package: 'fontawesome',
    icon_type : 'fas',
    icon : 'chart-line'
   },
  {
    header: 'Comissões',
  },
  {
    title: 'Faturamento',
    route: 'revenues',
    package: 'fontawesome',
    icon_type: 'fas',
    icon: 'dollar-sign',
  },
  {
    header: 'Vendas',
  },
  {
    title: 'Contratos',
    route: 'contracts',
    package: 'fontawesome',
    icon_type: 'fas',
    icon: 'file-signature',
  },
  {
    title: 'Distratos',
    route: 'cancellations',
    package: 'fontawesome',
    icon_type: 'fas',
    icon: 'file-export',
  },
  {
    header: 'Cadastros',
  },
  {
    title: 'Agenda',
    route: 'calendar',
    package: 'fontawesome',
    icon_type: 'fas',
    icon: 'calendar-alt',
  },
  {
    title: 'Comunicados',
    route: 'communicateds',
    package: 'fontawesome',
    icon_type: 'fas',
    icon: 'copy',
  },
  {
    title: 'Locais de Plantão',
    route: 'locations',
    package: 'fontawesome',
    icon_type : 'fas',
    icon : 'map-marked-alt',
  },
]
