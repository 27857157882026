<template>
  <div class="navbar-container d-flex content align-items-center">
    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link class="nav-link" @click="toggleVerticalMenuActive">
          <feather-icon icon="MenuIcon" size="21" />
        </b-link>
      </li>
    </ul>

    <!-- Left Col -->
    <div
      class="bookmark-wrapper align-items-center flex-grow-1 d-none d-lg-flex"
    >
    </div>

    <b-navbar-nav class="nav align-items-center ml-auto">
      <dark-Toggler class="d-lg-block" />
      <notification-dropdown class="pr-2" />

      <b-nav-item-dropdown
        right
        toggle-class="d-flex align-items-center dropdown-user-link"
        class="dropdown-user"
      >
      <template #button-content v-if="loggedUser != null">
      <div class="d-sm-flex d-none user-nav">
        <p v-if="loggedUser != null" class="user-name font-weight-bolder mb-0">
          {{ loggedUser.user_fullname }}
        </p>
            <small v-if="loggedUser.user_role == 'administrator'" class="align-text-top text-capitalize">Administrador</small>
            <small v-if="loggedUser.user_role == 'manager'" class="align-text-top text-capitalize">Gerente</small>
            <small v-if="loggedUser.user_role == 'broker'" class="align-text-top text-capitalize">Corretor</small>
            <small v-if="loggedUser.user_role == 'real_estate'" class="align-text-top text-capitalize">Imobiliária</small>
      </div>
      <b-avatar
        size="40"
        :src="loggedUser.user_image"
        :text="avatarText(loggedUser.user_fullname)"
        variant="light-primary"
        badge
        class="badge-minimal"
        badge-variant="success"
        v-if="loggedUser != null"
      >
         </b-avatar>
    </template>
        <b-dropdown-item link-class="d-flex align-items-center" @click="logout">
          <feather-icon size="16" icon="LogOutIcon" class="mr-50" />
          <span>Logout</span>
        </b-dropdown-item>
      </b-nav-item-dropdown>
    </b-navbar-nav>
  </div>
</template>

<script>
import { avatarText } from "@core/utils/filter";
import NotificationDropdown from './NotificationDropdown.vue'
import store from "@/store";
import services from "@/services";
import {
  BLink,
  BNavbarNav,
  BNavItemDropdown,
  BDropdownItem,
  BAvatar,
} from "bootstrap-vue";
import DarkToggler from "@core/layouts/components/app-navbar/components/DarkToggler.vue";

export default {
  components: {
    NotificationDropdown,
    BLink,
    BNavbarNav,
    BNavItemDropdown,
    BDropdownItem,
    BAvatar,

    // Navbar Components
    DarkToggler,
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      avatarText,
      showNotifications : false,
    };
  },
  methods: {
    async logout() {
        await services.auth.logout()
    },

  },

  computed: {
    loggedUser() {
      if(store.state.user.loggedUser) {
        return store.state.user.loggedUser;
      }
      return store.state.user.loggedUser
    },    
  }
};
</script>
