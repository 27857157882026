<template>
    <div>
        <b-modal
            id="modal-scrollable"
            ref="vCommunicated"
            scrollable
            hide-footer
            size="lg"
            :title="vCommunicated.title"
            cancel-variant="outline-secondary"
        >
            <b-card-text>
                <p v-html="vCommunicated.description"></p>
            </b-card-text>
        </b-modal>

        <b-nav-item-dropdown
            class="dropdown-notification mr-25"
            menu-class="dropdown-menu-media"
            right
        >
            <template #button-content>
                <feather-icon
                    :badge="communicatedView"
                    badge-classes="bg-danger"
                    class="text-body"
                    icon="BellIcon"
                    size="21"
                />
            </template>

            <li class="dropdown-menu-header">
                <div class="dropdown-header d-flex">
                    <h4 class="notification-title mb-0 mr-auto">Notificações</h4>
                </div>
            </li>

            <!-- Notifications -->
            <vue-perfect-scrollbar
                v-if="communicateds != ''"
                :settings="perfectScrollbarSettings"
                class="scrollable-container media-list scroll-area"
                tagname="li"
            >
                <b-link
                    v-for="notification in communicateds"
                    :key="notification.uuid_communicated"
                    @click.stop="viewCommunicated(notification)"
                >
                    <b-media>
                        <b-row cols="12">
                            <b-col cols="8">
                                <p class="pl-1 media-heading">
                                    <span style="font-weight: 500">
                                        {{ notification.title }}
                                    </span>
                                </p>
                                <small class="pl-1 notification-text"
                                    >Clique para ver o conteúdo!</small
                                >
                            </b-col>
                            <b-col cols="4">
                                <div
                                    class="d-flex justify-content-end"
                                    style="padding-right: 5px"
                                >
                                    <b-row>
                                        <b-col>
                                            <b-badge
                                                :style="`background-color: ${notification.category.color}`"
                                            >
                                                {{ notification.category.description }}
                                            </b-badge>
                                        </b-col>
                                    </b-row>
                                </div>
                                <div
                                    class="d-flex justify-content-end"
                                    style="padding-right: 5px"
                                >
                                    <b-row>
                                        <b-col>
                                            <div
                                                v-for="views in notification.communicateds_views"
                                                :key="views.uuid_communicated"
                                            >
                                                <small
                                                    v-if="myuUid == views.user_uuid"
                                                    style="font-weight: 500"
                                                    class="notification-text"
                                                >
                                                    Lido
                                                </small>
                                            </div>
                                        </b-col>
                                    </b-row>
                                </div>
                            </b-col>
                        </b-row>
                    </b-media>
                </b-link>
            </vue-perfect-scrollbar>
        </b-nav-item-dropdown>
    </div>
</template>

<script>
import gql from "graphql-tag";
import services from "@/services";
const INSERT_VIEW = gql`
    mutation insertView($communicated_uuid: uuid!, $user_uuid: uuid!) {
        insert_communicateds_view(
            objects: { communicated_uuid: $communicated_uuid, user_uuid: $user_uuid }
        ) {
            affected_rows
        }
    }
`;

import {
    BNavItemDropdown,
    BBadge,
    BCardText,
    BRow,
    BCol,
    BMedia,
    BLink,
} from "bootstrap-vue";
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import Ripple from "vue-ripple-directive";
import store from "@/store";
export default {
    components: {
        BNavItemDropdown,
        BBadge,
        BCardText,
        BRow,
        BCol,
        BMedia,
        BLink,
        VuePerfectScrollbar,
    },
    directives: {
        Ripple,
    },
    data() {
        return {
            vCommunicated: "",
            myuUid: [],
        };
    },
    created() {
        if (this.loggedUser) {
            this.getRecords();
            this.myuUid = this.loggedUser.uuid_user;
        }
    },

    methods: {
        viewCommunicated(communicated) {
            let lido = false;
            this.vCommunicated = communicated;
            this.$refs["vCommunicated"].show();
            for (let j = 0; j < communicated.communicateds_views.length; j++) {
                if (communicated.communicateds_views[j].user_uuid == this.myuUid) {
                        lido = true;
                }
            }

            if (lido == false) {
                this.insertView(communicated.uuid_communicated);
            }
            this.getRecords();
        },
        insertView(uuid_communicated) {
            this.$apollo.mutate({
                mutation: INSERT_VIEW,
                variables: {
                    communicated_uuid: uuid_communicated,
                    user_uuid: this.myuUid,
                },
            });
            this.getRecords();
        },
        getRecords() {
            services.dashboard.getNotifications(
                this.loggedUser.user_role,
                this.loggedUser.uuid_user
            );
        },
    },
    setup() {
        const perfectScrollbarSettings = {
            maxScrollbarLength: 60,
            wheelPropagation: false,
        };

        return {
            perfectScrollbarSettings,
        };
    },
    computed: {
        loggedUser() {
            if (store.state.user.loggedUser) {
                return store.state.user.loggedUser;
            } else {
                return null;
            }
        },

        communicateds() {
            return store.state.dashboard.notifications;
        },

        communicatedView() {
            return store.state.dashboard.notificationsCount;
        },
    },
};
</script>
