<template>
  <div>
    <b-modal
      id="modal-scrollable"
      ref="vCommunicated"
      scrollable
      hide-footer
      size="lg"
      :title="vCommunicated.title"
      cancel-variant="outline-secondary"
    >
      <b-card-text>
        <p v-html="vCommunicated.description"></p>
      </b-card-text>
    </b-modal>
    <b-nav-item-dropdown
      class="dropdown-notification mr-25"
      menu-class="dropdown-menu-media"
      right
    >
      <template #button-content>
        <feather-icon
          badge="2"
          badge-classes="bg-danger"
          class="text-body"
          icon="BellIcon"
          size="21"
        />
      </template>

      <li class="dropdown-menu-header">
        <div class="dropdown-header d-flex">
          <h4 class="notification-title mb-0 mr-auto">Notificações</h4>
        </div>
      </li>

      <!-- Notifications -->
      <vue-perfect-scrollbar
        v-if="communicateds != ''"
        v-once
        :settings="perfectScrollbarSettings"
        class="scrollable-container media-list scroll-area"
        tagname="li"
      >
        <b-link
          v-for="notification in communicateds"
          :key="notification.title"
          @click.stop="viewCommunicated(notification)"
        >
          <b-media>
            <b-row cols="12">
              <b-col cols="8">
                <p class="pl-1 media-heading">
                  <span style="font-weight: 500">
                    {{ notification.title }}
                  </span>
                </p>
                <small class="pl-1 notification-text"
                  >Clique para ver o conteúdo!</small
                >
              </b-col>
              <b-col cols="4">
                <div
                  class="d-flex justify-content-end"
                  style="padding-right: 5px"
                >
                  <b-row>
                    <b-col>
                      <b-badge
                        :style="`background-color: ${notification.category.color}`"
                      >
                        {{ notification.category.description }}
                      </b-badge>
                    </b-col>
                  </b-row>
                </div>
                <div
                  class="d-flex justify-content-end"
                  style="padding-right: 5px"
                >
                  <b-row>
                    <b-col>
                      <div
                        v-for="views in notification.communicateds_views"
                        :key="views.uuid_communicated"
                      >
                        <small
                          v-if="myuUid == views.user_uuid"
                          style="font-weight: 500"
                          class="notification-text"
                        >
                          Lido
                        </small>
                       <!--  <div
                          v-else
                          style="
                            width: 12px;
                            height: 12px;
                            background-color: #2d88ff;
                            border-radius: 50%;
                          "
                        ></div> -->
                      </div>
                    </b-col>
                  </b-row>
                </div>
              </b-col>
            </b-row>
          </b-media>
        </b-link>
      </vue-perfect-scrollbar>
    </b-nav-item-dropdown>
  </div>
</template>

<script>
import gql from "graphql-tag";

const INSERT_VIEW = gql`
mutation insertView($communicated_uuid: uuid!, $user_uuid: uuid!) {
  insert_communicateds_view(objects: {communicated_uuid: $communicated_uuid, user_uuid: $user_uuid}) {
    affected_rows
  }
}
`
;
const GET_COMMUNICATEDS = gql`
  query getCommunicateds {
    communicateds(
      order_by: { updated_at: desc }
      where: { broker_view: { _eq: true } }
    ) {
      uuid_communicated
      title
      description
      real_state_view
      broker_view
      created_at
      expires_at
      updated_at
      status
      user {
        user_image
        user_fullname
        user_role
      }
      category {
        uuid_category
        description
        color
      }
      communicateds_views {
        user_uuid
        created_at
      }
    }
  }
`;

import {
  BNavItemDropdown,
  BBadge,
  BCardText,
  BRow,
  BCol,
  BMedia,
  BLink /*  BAvatar, */ /* BButton, */ /* BFormCheckbox */,
} from "bootstrap-vue";
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import Ripple from "vue-ripple-directive";
import store from "@/store";
export default {
  components: {
    BNavItemDropdown,
    BBadge,
    BCardText,
    BRow,
    BCol,
    BMedia,
    BLink,
    VuePerfectScrollbar,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      communicateds: [],
      vCommunicated: "",
      myuUid: [],
    };
  },
  created() {
    if(this.loggedUser) {
      this.getRecords();
      this.myuUid = this.loggedUser.uuid_user;
    }
  },
  methods: {
    viewCommunicated(communicated) {
      this.vCommunicated = communicated;
      this.$refs["vCommunicated"].show();
      this.insertView(communicated.uuid_communicated);
      this.getRecords();
    },
    insertView(uuid_communicated) {
      this.$apollo
        .mutate({
          mutation: INSERT_VIEW,
          variables: {
            communicated_uuid : uuid_communicated,
            user_uuid : this.loggedUser.uuid_user
          },
        })
        .then(() => {
          // Visualizou
        })
        .catch(() => {
          // Erro
        });
    },
    getRecords() {
      this.$apollo
        .query({
          query: GET_COMMUNICATEDS,
          fetchPolicy: "no-cache",
        })
        .then((data) => {
          this.communicateds = data["data"]["communicateds"];
        });
    },
  },
  computed: {
        loggedUser() {
            if (store.state.user.loggedUser) {
                return store.state.user.loggedUser
            }
            else {
                return null
            }
        },
  },
  setup() {
    /* eslint-disable global-require */
    const notifications = [
      {
        title: "Congratulation Sam 🎉",
        avatar: require("@/assets/images/avatars/6-small.png"),
        subtitle: "Won the monthly best seller badge",
        type: "light-success",
      },
      {
        title: "New message received",
        avatar: require("@/assets/images/avatars/9-small.png"),
        subtitle: "You have 10 unread messages",
        type: "light-info",
      },
      {
        title: "Revised Order 👋",
        avatar: "MD",
        subtitle: "MD Inc. order updated",
        type: "light-danger",
      },
    ];
    /* eslint-disable global-require */

    const systemNotifications = [
      {
        title: "Server down",
        subtitle: "USA Server is down due to hight CPU usage",
        type: "light-danger",
        icon: "XIcon",
      },
      {
        title: "Sales report generated",
        subtitle: "Last month sales report generated",
        type: "light-success",
        icon: "CheckIcon",
      },
      {
        title: "High memory usage",
        subtitle: "BLR Server using high memory",
        type: "light-warning",
        icon: "AlertTriangleIcon",
      },
    ];

    const perfectScrollbarSettings = {
      maxScrollbarLength: 60,
      wheelPropagation: false,
    };

    return {
      notifications,
      systemNotifications,
      perfectScrollbarSettings,
    };
  },
};
</script>

<style>
</style>
