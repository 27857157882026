export default [
  {
    header: 'Acesso Rápido',
  },  
  {
    title: 'Dashboard',
    route: 'dashboard-real-estate',
    package: 'fontawesome',
    icon_type : 'fas',
    icon : 'chart-line'
  },
  {
    header: 'Comissões',
  },  
  {
    title: 'Faturamento',
    route: 'revenues',
    package: 'fontawesome',
    icon_type : 'fas',
    icon : 'dollar-sign',
  },
  {
    header: 'Vendas',
  },  
  {
    title: 'Contratos',
    route: 'contracts',
    package: 'fontawesome',
    icon_type : 'fas',
    icon : 'file-signature',
  },
  {
    title: 'Distratos',
    route: 'cancellations',
    package: 'fontawesome',
    icon_type : 'fas',
    icon : 'file-export',
  },
]
