<template>
  <p class="clearfix mb-0">
    <span class="float-md-left d-block d-md-inline-block mt-25">
      Copyright  © {{ new Date().getFullYear() }}
      <b-link
        class="ml-25"
        href="https://www.saobento.com.br"
        target="_blank"
      >São Bento Incorporadora</b-link>
    </span>
     <span class="float-md-right d-none d-md-block">Feito com
      <feather-icon
        icon="CoffeeIcon"
        size="18"
        class="stroke-current"
      />
      <span style="margin-left:8px;">em Dourados-MS.</span>
    </span>
  </p>
</template>

<script>
import { BLink } from 'bootstrap-vue'

export default {
  components: {
    BLink,
  },
}
</script>
