export default [
  {
    header: 'Acesso rápido',
  },  
  {
    title: 'Dashboard',
    route: 'dashboard-admin',
    package: 'fontawesome',
    icon_type : 'fas',
    icon : 'chart-line'
   },
  {
    title: 'Agenda',
    route: 'calendar',
    package: 'fontawesome',
    icon_type : 'fas',
    icon : 'calendar-alt',
  },
  {
    title: 'Comunicados',
    route: 'communicateds',
    package: 'fontawesome',
    icon_type : 'fas',
    icon : 'copy',
  },

  {
    header: 'Cadastros',
  },  
  {
    title: 'Usuários',
    route: 'users',
    package: 'fontawesome',
    icon_type : 'fas',
    icon : 'user-friends',
  },
  {
    title: 'Cidades',
    route: 'cities',
    package: 'fontawesome',
    icon_type : 'fas',
    icon : 'city',
  },
  {
    title: 'Locais de Plantão',
    route: 'locations',
    package: 'fontawesome',
    icon_type : 'fas',
    icon : 'map-marked-alt',
  },
  {
    title: 'Categorias',
    route: 'categories',
    package: 'fontawesome',
    icon_type : 'fas',
    icon : 'list',
  },
]
